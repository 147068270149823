/*FONTS*/
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v103/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.gmnoprint{
    display: none;
}
.gm-style-cc{
    display: none;
}
.pac-container:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
    background-image: none !important;
    height: 0px;
}
/*Barra scroll*/
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ccc #EBEBE6;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: #EBEBE6;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 0px;
  border: none;
}

/*COMMON*/
body{
    font-family: 'Roboto', sans-serif!important;
    color: #000;
}
/*strong {
    padding-right: 1%;
}*/
.App{
    background: #EBEBE6;
}
.block{
    overflow: hidden!important;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.hidden{
  display: none!important;
}
.jump_content{
    opacity: 0;
    position: absolute;
    z-index: -1000;
}
.jump_content:focus{
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    background: rgb(210 10 17 / 90%);
    text-align: center;
    z-index: 100;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    width: calc(100% - 4px);
    opacity: 1;
}
.ancla_enlace{
    position: absolute;
    top: -160px;
    z-index: -1;
}
.text_description{
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 20px;
}
.text_description_center {
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0;
    text-align: center;
}
.block_color{
    background: #067F8D;
    color: #fff;
    padding: 20px 0px 40px;
}
.title_h1{
    margin: 40px 0 10px;
    font-size: 24px;
    font-weight: 500;
}
.button_bm{
    background: #D20A11;
    display: block;
    border-radius: 8px;
    margin: 0 auto 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    min-width: 100px;
    max-width: 300px;
    padding: 10px;
    min-height: 40px;
    border: 1px solid transparent;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
}
.button_bm:hover, .button_bm:focus{
    border-color: #D20A11;
    color: #D20A11;
    background: #fff;
}
.link_more, .link_more_static{
    display: block;
    margin: 20px 0 30px;
    color: #333;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
}
.link_more_static.left{
    text-align: left;
}
.link_more.mensaje{
    font-size: 14px;
}
.link_more:hover, .link_more:focus,
.link_more_static:hover, .link_more_static:focus{
    text-decoration: none;
    color: #333;
}
.link_more > img,
.link_more_static > img{
    margin-left: 10px;
}
.link_more img.normal,
.link_more_static img.normal,
.paradas_linea .list_paradas .item .link img.normal,
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .link img.normal{
    display: initial;
}
.link_more img.hover,
.link_more_static img.hover,
.paradas_linea .list_paradas .item .link img.hover,
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .link img.hover{
    display: none;
}
.link_more:hover img.normal, .link_more:focus img.normal,
.link_more_static:hover img.normal, .link_more_static:focus img.normal,
.paradas_linea .list_paradas .item .link:hover img.normal, .paradas_linea .list_paradas .item .link:focus img.normal,
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .link:hover img.normal, .paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .link:focus img.normal{
    display: none;
}
.link_more:hover img.hover, .link_more:focus img.hover,
.link_more_static:hover img.hover, .link_more_static:focus img.hover,
.paradas_linea .list_paradas .item .link:hover img.hover, .paradas_linea .list_paradas .item .link:focus img.hover,
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .link:hover img.hover, .paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .link:focus img.hover{
    display: initial;
}
@media only screen and (min-width: 1300px){
    .planificador_sections.container,
    .section_detail_line.container{
        max-width: 1240px!important;
    }
}
@media only screen and (max-width: 991px){
    .no_mobile{
        display: none!important;
    }
    .section_detail_rute .container,
    .section_linea_horarios .container{
        max-width: 100%!important;
    }
}
@media only screen and (max-width: 767px){
    .container{
        max-width: 100%!important;
    }
}
@media only screen and (min-width: 768px){
    .link_more{
        position: absolute;
        top: 0px;
        right: 25px;
        margin: 0;
    }
    .link_more_static{
        text-align: right;
    }
}
.background{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.4);
}
.cabecera{
    min-height: 250px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    position: relative;
    background: url('../images/02_ilustracion_descubre_bizkaia_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cabecera .title{
    color: #fff;
    position: relative;
    text-align: center;
    padding: 60px 20px 0px;
    font-size: 24px;
    font-weight: 400;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
}
@media only screen and (min-width: 992px){
    .cabecera .title{
        padding: 200px 50px 150px;
    }
}
.modal_bm .modal-content{
    background: #fff;
    padding: 30px 20px 20px;
    border-radius: 8px;
}
.modal_bm .modal-content .title{
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px;
}
.modal_bm.big .modal-dialog{
    max-width: 1000px;
}
.modal_bm .modal-content .description{
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 15px;
}
.modal_bm .modal-content .content_image img{
    width: 100%;
}
.modal_bm .modal-content .close{
    position: absolute;
    top: 8px;
    right: 10px;
    color: #000;
    opacity: 1;
}
.modal_bm .modal-content .close:hover, .modal_bm .modal-content .close:focus{
    opacity: 0.8;
}
.modal_bm fieldset legend{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 20px;
}
.modal_bm fieldset .item_check{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    padding: 5px 30px;
    margin-bottom: 20px;
    position: relative;
}
.modal_bm fieldset .item_check input{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
}
.modal_bm fieldset .item_check input[type=checkbox] + label{
  cursor:pointer;
}
.modal_bm fieldset .item_check label:before{
  content:'';
  background:transparent;
  border: 2px solid #D20A11;
  border-radius: 5px;
  display: inline-block;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 30px;
}
.modal_bm fieldset .item_check input[type=checkbox]:checked + label:before{
  content: 'done';
  font-size: 16px;
  font-family: 'Material Icons';
  color: #fff;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
}
.modal_bm fieldset .item_check label{
    margin: 0;
    display: flex;
    align-items: center;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    padding-left: 25px;
}
.modal_bm fieldset .item_check label span{
    margin-left: 20px;
    color: #fff;
    background: #294309;
    padding: 5px;
    border-radius: 6px;
    height: 50px;
    display: flex;
    align-items: center;
}
.modal_bm fieldset .item_check label span.blue{
    background: #067F8D;
}
.modal_bm fieldset .item_check label span.green{
    background: #338B00;
}
.modal_bm fieldset .item_check label span.orange{
    background: #FF4713;
}
.modal_bm .form_bm input{
    border: none;
    border-radius: 8px;
    margin: 10px 0;
    background: #fff;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    padding: 15px;
    min-height: 45px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}
.modal_bm .form_bm input::placeholder{
    color: #666;
}
.button_modal{
    background: #D20A11;
    border-radius: 8px;
    padding: 15px 20px;
    text-align: center;
    width: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0 0;
    border: 1px solid transparent;
    display: block;
}
.button_modal.gray {
    background: #666
}
.button_modal.white {
    background: #fff;
    border: 1px solid #666;
    color: #000;
}
.button_modal:hover, .button_modal:focus{
    background: #fff;
    border-color: #D20A11;
    color: #D20A11;
    text-decoration: none;
}
.button_modal.small {
    width: fit-content;
}

/*Modal cookies*/
.modal_cookies .list_configuracion {
    margin: 0
}

.dropdown-menu.dropdown_bm{
    box-shadow: 0px 5px 5px rgba(0,0,0,0.3);
    border-radius: 8px;
}
.dropdown-menu.dropdown_bm .dropdown-item{
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    color: #000;
}
.dropdown-menu.dropdown_bm .dropdown-item span{
    margin-right: 10px;
}
.dropdown-menu.dropdown_bm .dropdown-item:hover, .dropdown-menu.dropdown_bm .dropdown-item:focus {
    color: #000;
    text-decoration: none;
    background-color: #ebebe6;
}
.select_box{
    position: absolute;
    top: 60px;
    left: 20px;
    padding: 0;
    margin: 0;
    list-style:none;
    padding: 10px 0;
    border-radius: 8px;
    background: #fff;
    z-index: 10;
    max-width: calc(100% - 40px);
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    text-align: left;
}
.select_box:before{
    content: "";
    width: 20px;
    height: 20px;
    background: #fff;
    position: absolute;
    top: -10px;
    left: 20px;
    transform: rotate(45deg);
    z-index: 0;
}
.select_box a{
    font-size: 12px;
    color: #000;
    padding: 5px 15px;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    position: relative;
}
.select_box a:hover, .select_box a:focus{
    background: #ebebe6;
    color: #000;
    text-decoration: none;
}
.section_collapse{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    position: relative;
}
.section_collapse li{
    width: 100%;
}
.section_collapse .item_head{
    position: relative;
}
.section_collapse .item_head .subtitle{
    margin: 0 0 5px;
}
.section_collapse .item_head button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #D20A11;
    border-radius: 8px;
    padding: 10px;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    border: none;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
    position: relative;
    text-align: left;
}
.section_collapse .item_head button:not(.collapsed) .arrow{
    transform: rotate(180deg);
    color: #fff;
}
.section_collapse .item_head button.collapsed .arrow{
    color: #D20A11;
}
.section_collapse .item_head button.collapsed{
    color: #000;
    background: #EBEBE6;
    box-shadow: none;
}
.section_collapse .item_head .text{
    padding-left: 50px;
    position: relative;
    text-align: left;
}
.section_collapse .item_head button .text:before{
    content: "";
    width: 30px;
    height: 100%;
    position: absolute;
    left: 5px;
    background: url('../images/icons/Icon_card_white.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.section_collapse .item_head button.collapsed .text:before{
    background: url('../images/icons/Icon_card_black.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.section_collapse .content_body{
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #EBEBE6;
    margin: 5px 0 10px;
}
.section_collapse .content_body p{
    font-size: 14px;
    margin: 0 0 15px;
}
.section_collapse .content_body li{
    font-size: 14px;
}
.section_collapse .content_body p a{
    color: #D20A11;
}
.section_collapse .content_body .link_more{
    position: initial;
    margin-bottom: 0px;
}
@media only screen and (min-width: 992px){
    .section_collapse .content_body{
        position: absolute;
        left: 10px;
        z-index: 2;
        width: calc(100% - 20px);
    }
}
.text_results{
    font-size: 18px;
    font-weight: 400;
    margin: 20px 0 0;
}
.select_linea{
    background: #067F8D;
    padding: 10px 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto 20px;
    width: 100%;
}
.select_linea .button_buscar{
    background: #D20A11;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    min-width: 100px;
    max-width: 150px;
    padding: 10px;
    margin-left: 10px;
    min-height: 40px;
    border: 1px solid transparent;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
}
.select_linea .button_buscar:hover, .select_linea .button_buscar:focus{
    border-color: #D20A11;
    color: #D20A11;
    background: #fff;
}
.select_linea input{
    padding: 10px 15px;
    min-height: 40px;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    color: #333;
    font-size: 14px;
    width: 100%;
}
.mark_line{
    width: 20px;
    display: flex;
    justify-content: center;
    margin-right: 15px;
}
.mark_line img{
    position: absolute;
    top: 0px;
}
.mark_line .line{
    width: 2px;
    height: 65px;
    background: #067F8D;
    margin: 0;
    position: relative;
}
.mark_line .line:before{
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #067F8D;
    background: #067F8D;
    position: absolute;
    top: 46px;
    left: -4px;
    border-radius: 100%;
}
/*Input configuración*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0;
}
.switch input {
  opacity: 1;
  width: 60px;
  height: 34px;
  appearance: none;
  border: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7A7A7A;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 36px;
  width: 36px;
  left: -5px;
  bottom: -1px;
  background-color: #fff;
  border: 1px solid #7A7A7A;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #D20A11;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
  border: 1px solid #D20A11;
}
/*Listados cnfiguracion*/
.list_configuracion{
    margin: 40px 0;
    padding: 0;
    list-style: none;
}
.list_configuracion .item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 0;
    margin: 0 0 10px;
    border-bottom: 1px solid #000;
}
.list_configuracion .item:last-child{
    border-bottom: none;
}
.list_configuracion .item .text{
    width: calc(100% - 80px);
}
.list_configuracion .item .text .subtitle{
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px;
}
.list_configuracion .item .text .description{
    font-size: 14px;
    font-weight: 300;
    margin: 0;
}


/*HEDAER*/
.app_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 14;
  min-height: 60px;
}
.app_header .header-logo .inside{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    text-decoration: none;
}
.app_header .header-logo .inside span{
    font-size: 30px;
}
.app_header .header-logo{
  display: flex;
  align-items: center;
  width: calc(100% - 70px);
}
.app_header .header-logo .home{
    width: 100%;
}
.app_header .header-logo h1{
  margin: 0;
  font-size: 22px;
  font-weight: 500;
}
.app_header .header-logo img.logo{
  /*width: auto;
  max-width: 100%;*/
  width: 100%;
  max-width: 200px;
}

.app_header .actions_header{
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.app_header .actions_header button{
  border: none;
  background: transparent;
  margin: 0;
  position: relative;
}
.app_header .actions_header button img{
    width: 100%;
    max-width: 25px;
    min-width: 25px;
}
.app_header .actions_header button .number_notification{
    background: #D20A11;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 10px;
    position: absolute;
    top: 0px;
    right: 10px;
    color: #fff;
}
header.desktop{
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 20;
}
header.desktop .header_logo{
    position: absolute;
    top: 0;
    /*background: #D20A11;*/
    /*padding: 30px 20px;*/
    left: 7%;
}
header.desktop .pre_header{
    background: #fff;
}
header.desktop .pre_header .container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
header.desktop .pre_header .list_items{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: inline-flex;
}
header.desktop .pre_header .list_items a{
    padding: 10px 20px;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-weight: 700;
    text-decoration: none;
}
header.desktop .pre_header .list_items a img{
    margin-right: 5px;
}
header.desktop .pre_header .list_items a:hover, header.desktop .pre_header .list_items a:focus{
    color: #000;
}
header.desktop .pre_header .section_languages{
    position: relative;
}
header.desktop .pre_header .section_languages button{
    background: transparent;
    padding: 10px 20px;
    border: none;
    color: #D20A11;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 80px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
}
header.desktop .pre_header .section_languages .select_languages{
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 80px;
    z-index: 20;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.1);
}
header.desktop .pre_header .section_languages .select_languages a{
    background: #fff;
    padding: 10px 20px;
    color: #D20A11;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
}
header.desktop .pre_header .section_languages .select_languages a:hover, header.desktop .pre_header .section_languages .select_languages a:focus{
    background: #000;
    color: #fff;
}
header.desktop .main_first{
    background: #000;
    color: #fff;
}
header.desktop .main_first .list_main{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
header.desktop .main_first .list_main li{
    position: relative;
}
header.desktop .main_first .list_main li a, header.desktop .main_first .list_main li button {
    color: #fff;
    padding: 20px;
    text-transform: uppercase;
    font-size: 13px;
    text-decoration: none;
    font-weight: 700;
    border-left: 1px solid #fff;
    display: block;
    background: transparent;
    min-height: 60px;
    border-top: none;
    border-bottom: none;
}
header.desktop .main_first .list_main li a:hover, header.desktop .main_first .list_main li a:focus{
    background: #fff;
    color: #000;
}
header.desktop .main_first .list_main li a.active, header.desktop .main_first .list_main li button.active{
    background: #fff;
    color: #000;
    border: none;
}
header.desktop .main_first .list_main li a.active:before, header.desktop .main_first .list_main li button.active:before{
    content: "";
    width: calc(100% - 40px);
    height: 2px;
    background: #d20a11;
    position: absolute;
    top: 0;
    left: 20px;
}
header.desktop .main_first .list_main li a.icono{
    background: #D20A11;
    color: #fff;
    padding: 10px 20px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
header.desktop .main_first .list_main li a.icono:hover, header.desktop .main_first .list_main li a.icono:focus{
    background: #fff;
    color: #fff;
}
header.desktop .main_first .list_main li a.icono:hover img, header.desktop .main_first .list_main li a.icono:focus img{
    filter: invert(1);
}
header.desktop .main_first .list_main li a.icono .number_notification{
    background: #000;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 10px;
    position: absolute;
    top: 15px;
    right: 20px;
}
header.desktop .main_first .list_main .sub_menu{
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    left: 0;
    width: calc(100% + 50%);
}
header.desktop .main_first .list_main .sub_menu a{
    padding: 10px 20px;
    color: #D20A11;
    border: none;
    background: #fff;
    min-height: auto;
}
header.desktop .main_first .list_main .sub_menu a:hover, header.desktop .main_first .list_main .sub_menu a:focus{
    color: #000;
}
@media only screen and (max-width: 400px){
    .app_header .header-logo h1{
        font-size: 18px;
    }
}

/*FOOTER*/
.app_footer{
    background: #fff;
    text-align: center;
}
.app_footer > img{
    margin: 20px;
    width: 100%;
    max-width: 150px;
}
.app_footer .container_fullgray{
     background: #ebebe6;
     padding: 30px 10px 120px;
}
.app_footer .container_fullgray .powered_by{
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 20px;
}
.app_footer .container_fullgray .main_footer{
    justify-content: center;
}
.app_footer .container_fullgray .main_footer a{
    color: #333;
    font-size: 12px;
    text-decoration: underline;
    margin: 5px;
}
.app_footer img{
    width: 100%;
    max-width: 180px;
}
@media only screen and (min-width: 992px){
    .app_footer .container_fullgray{
        padding: 30px 10px;
    }
    .app_footer .container_fullgray .main_footer {
        justify-content: start;
    }
    .app_footer .container_fullgray .main_footer a{
        margin: 0 10px 0 0;
    }
    .app_footer .container_fullgray .powered_by{
        text-align: left;
    }
}

/*MENU*/
.menu{
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    height: 100vh;
    z-index: 20;
    width: 90%;
}
.menu .header-menu{
    position: absolute;
    top: 10px;
    right: 5px;
    border: none;
    background: transparent;
}
.menu .header-menu img{
    filter: invert(1);
}
.menu .header_menu{
    background: #333;
    border-bottom-left-radius: 25px;
    padding: 20px 10px;
    height: 150px;
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.menu .header_menu:before {
    content: "";
    width: 20px;
    height: 20px;
    border-top-right-radius: 25px;
    background-color: #fff;
    position: absolute;
    top: 150px;
    right: 20px;
    z-index: 1;
}
.menu .header_menu::after {
    content: "";
    width: 40px;
    height: 20px;
    background-color: #1c2628;
    position: absolute;
    top: 150px;
    right: 0px;
}
.menu .header_menu .logo{
    margin: 0;
}
.menu .header_menu .logo img{
    /*width: auto;
    max-width: 100%;*/
    padding: 10px 20px;
    width: 100%;
    max-width: 220px;
}
.menu .languages{
    display: flex;
    align-items: center;
    padding: 0px 20px;
    margin-top: 10px;
}
.menu .languages p{
    color: #fff;
    margin: 0;
    font-size: 14px;
}
.menu .languages ul{
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0 15px;
    margin: 0;
}
.menu .languages ul a{
    color: #fff;
    padding: 2px;
    display: flex;
    align-items: center;
    background: #666;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    margin-right: 15px;
    text-decoration: none;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
    border: 1px solid transparent;
}
.menu .languages ul a:hover, .menu .languages ul a:focus,
.menu .languages ul a.active:hover, .menu .languages ul a.active:focus{
    background: #fff;
    color: #d20a11;
    border: 1px solid #d20a11;
}
.menu .languages ul a.active{
    background: #d20a11;
}
.menu .section_menus_mobile{
    height: calc(100vh - 150px);
    overflow: auto;
    border-right: 20px solid #1c2628;
}
.menu .main_first{
    padding: 30px 0 0;
    margin: 0 0 15px;
    list-style: none;
}
.menu .main_first a{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 90%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    color: #d20a11;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}
.menu .main_first a > img{
    margin-right: 10px;
}
.menu .main_first a:hover, .menu .main_first a:focus, .menu .main_first a.active{
    background: #d20a11;
    color: #fff;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
}
.menu .main_first a:hover img, .menu .main_first a:focus img, .menu .main_first a.active img{
    filter: saturate(1) brightness(0.1) invert(1);
}
.menu .main_second{
    margin: 20px 20px 80px;
    list-style: none;
    border-top: 2px solid #eee;
    padding: 20px 0 0;
}
.menu .main_second a{
    padding: 10px;
    color: #333;
    display: block;
    text-decoration: none;
    font-size: 14px;
}
.menu .main_second a:hover, .menu .main_second a:focus{
    opacity: 0.8;
}

/*ACCESOS RÁPIDOS*/
.app_accesoRapido{
    background: #333;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom: 8px solid #d20a11;
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 10;
}
.app_accesoRapido a{
  margin: 0;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  font-size: 12px;
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.app_accesoRapido a.btn_lineas{
    border-left: 0.5px solid rgba(255,255,255,0.1);
    border-top-right-radius: 25px;
}
.app_accesoRapido a.btn_planificador{
    border-top-left-radius: 25px;
}
.app_accesoRapido a:hover, .app_accesoRapido a:focus{
    color: #fff;
    text-decoration: none;
}
.app_accesoRapido a p{
  margin: 10px 0 0;
}
.app_accesoRapido a.active{
    background: #434343;
}
.app_accesoRapido a.active .icono{
    background: red;
    width: 45px;
    border-radius: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    position: absolute;
    top: -20px;
    left: calc(50% - 20px);
}
.app_accesoRapido a .icono img{
    width: 25px;
    max-width: 25px;
}

/*SECTION PLANIFICADOR RUTA*/
.section_planificador_ruta{
    background: #333;
    padding: 100px 30px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background: url('../images/01_ilustracion_home_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}
.section_planificador_ruta .title{
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 40px;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
    position: relative
}
.section_planificador_ruta .container_planificador{
    position: relative;
}
.section_planificador_ruta .container_planificador .button_invert,
.cabecera_planificador .button_invert{
    position: absolute;
    background: transparent;
    border:none;
    right: -40px;
    top: 40px;
}
.section_planificador_ruta .container_planificador .button_invert:hover, .section_planificador_ruta .container_planificador .button_invert:focus{
    opacity: 0.8;
}
.section_planificador_ruta .container_planificador input,
.cabecera_planificador input{
    padding: 10px 15px;
    min-height: 40px;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    color: #333;
    font-size: 14px;
    width: 100%;
    padding-right: 40px;
}
.section_planificador_ruta .container_planificador input::placeholder,
.cabecera_planificador input::placeholder{
    color: #333;
}
.section_planificador_ruta .container_planificador .button_buscar,
.cabecera_planificador .button_buscar{
    background: #d20a11;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    margin: 0 0 0 auto;
    display: block;
    font-size: 14px;
    font-weight: 500;
    min-width: 150px;
    color: #fff;
    border: 1px solid transparent;
}
.section_planificador_ruta .container_planificador .button_buscar:hover, .section_planificador_ruta .container_planificador .button_buscar:focus,
.cabecera_planificador .button_buscar:hover, .cabecera_planificador .button_buscar:focus{
    background: #fff;
    border: 1px solid #d20a11;
    color: #d20a11;
}
.container_planificador .mb-3{
    position: relative;
}
.container_planificador .mb-3 .button_location{
    background: transparent;
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 4px;
    padding-right: 10px;
    padding-left: 10px;
    z-index: 1;
}
@media only screen and (min-width: 992px){
    .section_planificador_ruta{
        padding-top: 100px!important;
        padding-bottom: 150px!important;
        text-align: center;
        margin-top: 100px;
    }
    .section_planificador_ruta .container_planificador{
        display: inline-flex;
        align-content: center;
        justify-content: center;
    }
    .section_planificador_ruta .container_planificador .mb-3{
        margin-bottom: 0px!important;
        margin-right: 50px;
    }
    .section_planificador_ruta .container_planificador .button_buscar{
        margin: 0 0 0 -30px;
    }
    .section_planificador_ruta .container_planificador input{
        width: 310px;
        text-align: left;
    }
    .section_planificador_ruta .container_planificador .button_invert{
        left: 322px;
        top: 7px;
        right: auto;
    }
    .section_planificador_ruta .container_planificador .button_invert > img{
        transform: rotate(-90deg);
    }
}

/*SECTION ALERTAS*/
.section_alertas{
    margin: -80px auto 30px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 80px;
}
.container_alerta{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    padding: 0;
    border-left: 15px solid #40808d;
    margin: 30px 0 0;
    position: relative;
    width: 100%;
}
.container_alerta.linea{
    border-color: #D20A11;
}
.container_alerta .alertas-btn{
    border: none;
    background: transparent;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
}
.container_alerta .alertas-btn:hover, .container_alerta .alertas-btn:focus{
    opacity: 0.8;
}
.container_alerta .icono_notificacion{
    position: absolute;
    top: -23px;
    left: 5px;
    width: 50px;
    margin: 0;
}
.container_alerta .content_alert{
    padding: 30px 20px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.container_alerta .content_alert .fecha{
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 10px;
    color: #666;
}
.container_alerta.linea .content_alert .title{
    margin: 0;
}
.container_alerta .content_alert .title{
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 10px;
}
.container_alerta .content_alert .description{
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 10px;
}
.link_all{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.container_alerta .content_alert .link{
    display: block;
    align-items: center;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    margin: 0;
}
.container_alerta .content_alert .link > img{
    margin-left: 10px;
}
.container_alerta .content_alert .link > img.normal{
    display: initial;
}
.container_alerta .content_alert .link > img.hover{
    display: none;
}
.container_alerta .content_alert .link_all:hover ~ .link > img.normal, .link_all:focus ~ .link > img.normal{
    display: none;
}
.container_alerta .content_alert .link_all:hover ~ .link > img.hover, .link_all:focus ~ .link > img.hover{
    display: initial;
}
@media only screen and (min-width: 992px){
    .container_alerta .content_alert .title{
        font-size: 14px;
    }
}
@media only screen and (min-width: 768px){
    .container_alerta{
        width: calc(50% - 20px);
        margin: 30px 10px 0;
    }
    .container_alerta .content_alert .description{
        font-size: 16px;
    }
}

/*LÍNEAS FAVORITAS Y RECIENTES*/
.section_lineas_favoritas{
    margin-top: 40px;
    position: relative;
}
.section_lineas_favoritas .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px
}
.section_lineas_favoritas  .lineas_list{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.section_lineas_favoritas  .lineas_list .item{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    padding: 15px;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.section_lineas_favoritas  .lineas_list .item .subtitle{
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 10px;
}
.section_lineas_favoritas  .lineas_list .item .description{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
}
.section_lineas_favoritas  .lineas_list .item .description > span{
    margin-right: 10px;
    color: #D20A11;
}
.section_lineas_favoritas  .lineas_list .item .link{
    display: flex;
    align-items: center;
    color: #333;
    text-decoration:none;
    border: none;
    z-index: 2;
    background: transparent;
    height: 100%;
    margin: -15px;
    padding: 0 20px;
}
.section_lineas_favoritas  .lineas_list .item .link:hover, .section_lineas_favoritas  .lineas_list .item .link:focus{
    opacity: 0.8;
}
@media only screen and (min-width: 992px){
    .section_lineas_favoritas .lineas_list .item{
        width: calc(33.3% - 20px)!important;
    }
    .section_lineas_favoritas .title{
        font-size: 24px;
    }
}
@media only screen and (min-width: 768px){
    .section_lineas_favoritas{
        margin-bottom:30px;
    }
    .section_lineas_favoritas .lineas_list{
        margin: 0 -10px;
    }
    .section_lineas_favoritas .lineas_list .item{
        width: calc(50% - 20px);
        margin: 0 10px 20px;
    }
}

/*TRANSPORTE PÚBLICO*/
.section_transporte_publico{
    background: #40808d;
    color: #fff;
    padding: 40px 0;
}
.section_transporte_publico .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 5px
}
.section_transporte_publico .description{
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 20px;
}
.section_transporte_publico .list_transporte{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.section_transporte_publico .list_transporte > li{
    width: 50%;
    margin-bottom: 15px;
}
.section_transporte_publico .list_transporte .link{
    display: block;
    background: #034D55;
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    position: relative;
    margin: 0 20px;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    text-decoration: none;
}
.section_transporte_publico .list_transporte .link:hover,
.section_transporte_publico .list_transporte .link:focus{
    background: #000;
}
.section_transporte_publico .list_transporte .link .icon{
    position: absolute;
    left: -25px;
    width: 100%;
    max-width: 50px;
}
.section_transporte_publico .list_transporte > li.show{
    width: 100%;
    margin-bottom: 50px;
}
.section_transporte_publico .list_transporte > li.show .link{
    font-size: 15px;
    min-height: 80px;
    padding-left: 60px;
    text-transform: uppercase;
    width: 85%;
}
.section_transporte_publico .list_transporte > li.show .link .icon{
    max-width: 100px!important;
    left: -50px;
}
.section_transporte_publico .list_transporte > li.show .link .image{
    position: absolute;
    bottom: -30px;
    right: -10%;
}

@media only screen and (min-width: 992px){
    .section_transporte_publico .title{
        font-size: 24px;
    }
    .section_transporte_publico .description{
        font-size: 18px;
    }
    .section_transporte_publico .list_transporte > li{
        width: 20%;
    }
    .section_transporte_publico .list_transporte > li.show{
        width: 40%;
    }
    .section_transporte_publico .list_transporte > li.show .link{
        font-size: 16px;
    }
    .section_transporte_publico .list_transporte .link{
        font-size: 14px;
        min-height: 80px;
    }
    .section_transporte_publico .list_transporte .link .icon{
        top: -25px;
        left: 0;
    }
}

/*DESCRUBRE BIZKAIA*/
.section_descrubre_bizkaia{
    padding: 40px 0;
    background: #ebebe6;
}
.section_descrubre_bizkaia .container{
    position: relative;
}
.section_descrubre_bizkaia .title {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
}
.section_descrubre_bizkaia .list_card{
    padding: 0 0 30px;
    margin: 0;
    list-style: none;
    display: inline-flex;
    overflow-x: auto;
    width: 100%;
}
.section_descrubre_bizkaia .list_card .list-item{
    margin: 0 10px 0 0;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    width: 280px;
    height: 160px;
    min-width: 280px;
}
.section_descrubre_bizkaia .list_card .list-item .background_image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.section_descrubre_bizkaia .list_card .list-item .content_text{
    color: #fff;
    background: rgba(0,0,0,0.7);
    border-radius: 8px;
    padding: 15px 15px 5px;
    border-bottom: 8px solid #d20a11;
    position: absolute;
    bottom: -8px;
    right: 10px;
    width: 160px;
}
.section_descrubre_bizkaia .list_card .list-item .content_text .subtitle{
    font-size: 16px;
    margin: 0 0 5px;
    font-weight: 500;
}
.section_descrubre_bizkaia .list_card .list-item .content_text .description{
    font-size: 12px;
    margin: 0 0 5px;
    font-weight: 400;
}
.section_descrubre_bizkaia .list_card .list-item .content_text .link{
    display: block;
    text-align: right;
    color: #fff;
    text-decoration: none;
}
.section_descrubre_bizkaia .link_more{
    margin-bottom: 0;
}
@media only screen and (min-width: 992px){
    .section_descrubre_bizkaia{
        background: #fff;
    }
    .section_descrubre_bizkaia .title{
        font-size: 24px;
    }
    .section_descrubre_bizkaia .list_card{
        overflow: hidden;
        flex-wrap: wrap;
        margin: 0 -10px;
        padding: 0;
    }
    .section_descrubre_bizkaia .list_card .list-item{
        width: calc(25% - 20px);
        min-width: calc(25% - 20px);
        margin: 0 10px 20px;
    }
}

/*SECCIÓN TÍTULOS*/
.section_titulos_trasnportes{
    padding: 40px 0;
    background: #fff;
}
.section_titulos_trasnportes .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
}
.section_titulos_trasnportes .section_collapse .item_body ul{
    padding-left: 20px;
}
.section_titulos_trasnportes .section_collapse .item_body li{
    width: 100%;
    list-style: disc;
}
@media only screen and (min-width: 992px){
    .section_titulos_trasnportes{
        background: #EBEBE6;
    }
    .section_titulos_trasnportes .title{
        font-size: 24px;
    }
    .section_titulos_trasnportes .section_collapse li{
        width: 25%;
        margin-bottom: 20px;
    }
    .section_titulos_trasnportes .section_collapse .item_head button{
        width: 85%;
        background: #D20A11;
        color: #fff;
    }
    .section_titulos_trasnportes .section_collapse .item_head button.collapsed {
        background: #fff;
        color: #000;
    }
    .section_titulos_trasnportes .section_collapse .item_head button .arrow{
        background: #D20A11;
        border-radius: 100%;
        color: #fff;
        position: absolute;
        right: -10px;
    }
    .section_titulos_trasnportes .section_collapse .item_head button.collapsed .arrow{
        transform: rotate(-90deg);
        color: #fff;
    }
    .section_titulos_trasnportes .section_collapse .item_head button:not(.collapsed) .arrow{
        transform: rotate(90deg);
    }
    .section_titulos_trasnportes .section_collapse .content_body{
        margin: 10px 0;
    }
    .section_titulos_trasnportes .section_collapse .item_head button .text:before{
        background: url('../images/icons/Icon_card_white.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .section_titulos_trasnportes .section_collapse .item_head button.collapsed .text:before{
        background: url('../images/icons/Icon_card_red.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .section_titulos_trasnportes .section_collapse .content_body{
        left: 0;
        width: 100%;
    }
}


/*SECTION BANNERS*/
.section_banners{
    background: #fff;
}
.section_banners .container > .row > div{
    padding: 0px;
}
.section_desarrolladores,
.section_bizkaibus_app{
    background: url('../images/background_forma.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    min-height: 200px;
    border-radius: 8px;
    margin-bottom: 20px;
    color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
}
.section_desarrolladores .title,
.section_bizkaibus_app .title{
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    width: 55%;
    position: relative;
    margin: 0 0 10px;
    z-index: 1;
}
.section_desarrolladores .description,
.section_bizkaibus_app .description{
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 10px;
    width: 50%;
    position: relative;
    z-index: 1;
}
.section_desarrolladores .link,
.section_bizkaibus_app .link{
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    position: relative;
    z-index: 1;
}
.section_desarrolladores .link img{
    margin-left: 10px;
}
.section_bizkaibus_app .links_apps{
    z-index: 1;
}
.section_desarrolladores .imagen_banner,
.section_bizkaibus_app .imagen_banner{
    position: absolute;
    bottom: 0;
    right: 0;
    height: auto;
    max-height: calc(100% + 40px);
    border-bottom-right-radius: 8px;
}
@media only screen and (min-width: 992px){
    .section_banners .container{
        padding: 0 15px;
    }
    .section_desarrolladores, .section_bizkaibus_app{
        min-height: 260px;
    }
    .section_desarrolladores .title, .section_bizkaibus_app .title{
        font-size: 32px;
    }
    .section_desarrolladores .description, .section_bizkaibus_app .description{
        font-size: 16px;
    }
    .section_desarrolladores .link, .section_bizkaibus_app .link{
        font-size: 14px;
    }
}
@media only screen and (min-width: 768px){
    .section_banners .container > .row > div{
        padding: 0 15px;
    }
}
@media only screen and (max-width: 320px){
    .section_desarrolladores .imagen_banner, .section_bizkaibus_app .imagen_banner{
        opacity: 0.5;
    }
}

/*PÁGINA DESCUBRE BIZKAIA*/
.cabecera_descubre{
    min-height: 250px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    position: relative;
    background: url('../images/02_ilustracion_descubre_bizkaia_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cabecera_descubre .title{
    color: #fff;
    position: relative;
    text-align: center;
    padding: 60px 20px 0px;
    font-size: 24px;
    font-weight: 400;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
}
@media only screen and (min-width: 992px){
    .cabecera_descubre{
        min-height: 200px;
        margin-top: 100px;
    }
    .cabecera_descubre .title{
        padding: 50px 50px 0px;
    }
}
.descubre_bizkaia .section_descrubre_bizkaia{
    background: #fff;
}
.descubre_bizkaia .section_descrubre_bizkaia .link_more{
    display: none;
}
.banner_full{
    background: #067F8D;
    padding: 50px 10px;
    position: relative;
    overflow: hidden;
}
.banner_full .title{
    font-size: 20px;
    margin: 0 0 30px;
    font-weight: 400;
    color: #fff;
    position: relative;
    z-index: 1;
}
.banner_full .image{
    position: absolute;
    bottom: -30%;
    right: -10%;
    z-index: 0;
}
.banner_full .section_collapse{
    position: relative;
}
.banner_full .section_collapse .item_head button.collapsed{
    background: #fff;
}
.banner_full .section_collapse .item_head button{
    background: #000;
}

@media only screen and (min-width: 768px){
    .banner_full{
        max-width: 800px;
        margin: 40px auto 0;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        padding: 40px 20px;
    }
    .banner_full .container{
        display: flex;
        align-items: center;
    }
    .banner_full .container > *{
        width: 50%;
    }
    .banner_full .title{
        font-size: 24px;
        margin: 0;
        padding-right: 40px;
    }
    .banner_full .section_collapse .content_body{
        width: 100%;
        position: initial;
    }
}
.section_places{
    background: #EBEBE6;
}
.places_interest{
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 10px 20px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}
.places_interest .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 10px;
    color: #fff;
}
.places_interest .subtitle{
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 10px;
    color: #fff;
}
.places_interest .list_items{
    padding: 0;
    margin: 20px 0 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.places_interest .list_items li{
    width: 25%;
}
.places_interest .list_items .link{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-decoration: none;
}
.places_interest .list_items .link span{
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
}
.places_interest .list_items .link span.image{
    margin-bottom: 5px;
    background: #000;
    padding: 2px;
    border-radius: 8px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
    border: 1px solid transparent;
}
.places_interest .list_items .link.active span.image,
.places_interest .list_items .link.active:focus span.image{
    background: #D20A11;
}
.places_interest .list_items .link:hover span.image,
.places_interest .list_items .link:focus span.image{
    border-color: #D20A11;
    color: #D20A11;
    background: #fff;
}
.places_interest .list_items .link:hover span.image .icon,
.places_interest .list_items .link:focus span.image .icon{
    color: #D20A11;
}
.places_interest .list_items .link span.image > img{
    width: 22px;
}
.places_interest .list_items .link span.image .normal,
.places_interest .list_items .link.active:focus span.image .normal{
    display: block;
}
.places_interest .list_items .link span.image .hover,
.places_interest .list_items .link.active:focus span.image .hover{
    display: none;
}
.places_interest .list_items .link:hover span.image .normal,
.places_interest .list_items .link:focus span.image .normal{
    display: none;
}
.places_interest .list_items .link:hover span.image .hover,
.places_interest .list_items .link:focus span.image .hover{
    display: block;
}

.places_interest_content{
    padding-top: 30px;
    padding-bottom: 30px;
}
.places_interest_content .list_content{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.places_interest_content .list_content .item{
    margin: 0 0 10px;
    width: 100%;
    position: relative;
}
.places_interest_content .list_content .item .link_more{
    background: #fff;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.06);
    position: initial;
    border-radius: 8px;
    width: calc(100% - 10px);
    margin: 0;
}
.places_interest_content .list_content .item .link_more:hover, .places_interest_content .list_content .item .link_more:focus{
    background: #000;
    color: #fff;
}
.places_interest_content .list_content .item .link_more:hover .categoria, .places_interest_content .list_content .item .link_more:focus .categoria{
    color: #fff;
}
.places_interest_content .list_content .item .categoria{
    color: #D20A11;
    font-size: 10px;
    font-weight: 700;
    margin: 0 10px 0 0;
    min-width: 45px;
}
.places_interest_content .list_content .item .title{
    font-size: 16px;
    font-weight: 400;
    margin: 0 20px 0 0;
}
.places_interest_content .list_content .item .arrow_link{
    position: absolute;
    right: -10px;
    top: 6px;
}
@media only screen and (min-width: 768px){
    .places_interest{
        max-width: 800px;
        margin: 0 auto;
    }
    .places_interest_content .list_content{
        margin: 0 auto;
        max-width: 780px;
    }
    .places_interest_content .list_content .item{
        margin: 0 10px 10px;
        /*width: calc(33.3% - 20px);*/
    }
}

/*PÁGINA PLANIFICADOR*/
.planificador_sections .row > div{
    padding: 0;
}
.planificador_sections .mapa_linea > div{
    height: 100%;
    min-height: 250px;
}
@media only screen and (min-width: 992px){
    .planificador_sections{
        margin-top: 100px;
    }
    .planificador_sections .content_left{
        height: calc(100vh - 100px);
        overflow-y: auto;
        background: #fff;
    }
}
@media only screen and (max-width: 991px){
    .planificador_sections.container{
        width: 100%;
        max-width: 100%;
    }
    .planificador_sections .mapa_linea{
        display: none;
    }
}
.cabecera_planificador{
    background: #333;
    padding: 100px 50px 20px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;
}
.cabecera_planificador .title_h1_desktop{
    display: none;
}
.cabecera_planificador .searh_rute{
    position: relative;
}
.cabecera_planificador .actions_rutes{
    margin: 20px -40px;
    background: #067F8D;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    position: relative;
}
.cabecera_planificador .actions_rutes .button_planificador{
    width: 100%;
    background: #000;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding: 10px 10px 10px 20px;
    text-align: left;
}
.cabecera_planificador .button_invert.change{
    position: absolute;
    background: transparent;
    border: none;
    left: -35px;
    right: auto;
    top: 20px;
}
.cabecera_planificador .button_invert.change.active{
    transform: rotate(180deg);
}
.cabecera_planificador .select_time a:hover, .cabecera_planificador .select_time a:focus{
    background: #ebebe6;
}
.cabecera_planificador .mb-3{
    position: relative;
}
.cabecera_planificador .mb-3 .button_location{
    background: transparent;
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 4px;
    padding-right: 10px;
    padding-left: 10px;
    z-index: 1;
}

@media only screen and (min-width: 992px){
    .cabecera_planificador{
        padding: 65px 40px 20px;

    }
    .cabecera_planificador .actions_rutes{
        margin: 30px -25px;
        max-width: 500px;
    }
    .cabecera_planificador .title_h1_desktop{
        display: block;
        text-align: left;
        color: #fff;
        font-size: 24px;
        margin: 0 -25px 20px;
    }
}
.content_results{
    padding-top:30px;
}
.content_results .content_select{
    position: relative;
}
.content_results .content_select .button_rutas{
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
}
.content_results .content_select .select_box{
    top: 45px;
    left: 0;
}
.content_results .direction-options{
    margin: 15px 0;
}
.content_results .direction-options .text_description{
    padding-left: 10px;
}
.content_results .direction-options .list_rutas{
    padding: 0;
    margin: 0;
    list-style: none;
}
.content_results .direction-options .list_rutas .direction-info{
    display: flex;
    background: #fff;
    border-radius: 8px;
    margin: 0 0 10px;
    overflow: hidden;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    width: 100%;
}
.content_results .direction-options .list_rutas .direction-info .link_ruta{
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    z-index: 1;
}
.content_results .direction-options .list_rutas .direction-info .left{
    padding: 10px;
    background: #EBEBE6;
    width: 30%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.content_results .direction-options .list_rutas .direction-info .left .total_time_spent{
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 5px;
}
.content_results .direction-options .list_rutas .direction-info .left .duration{
    display: flex;
    align-items: start;
    justify-content: center;
}
.content_results .direction-options .list_rutas .direction-info .left .duration > img{
    margin-right: 10px;
    margin-top: 3px;
}
.content_results .direction-options .list_rutas .direction-info .left .duration .start_end_time{
    margin: 0;
}
.content_results .direction-options .list_rutas .direction-info .left .duration .start_end_time span{
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 5px;;
}
.content_results .direction-options .list_rutas .direction-info .right{
    padding: 10px 10px 10px 0;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps{
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    padding:0;
    margin: 0;
    list-style: none;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item{
    width: 33.3%;
    display: flex;
    align-items: start;
    text-align: center;
    justify-content: space-between;
    padding: 0 10px;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item > div{
    width: 80%;
    position: relative;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item p{
    font-size: 10px;
    font-weight: 400;
    margin: 0;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item .alert{
    position: absolute;
    top: -18px;
    right: -24px;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item .separator{
    font-size: 18px;
    color: #666666;
    padding: 13px 10px 0;
    width: 20%;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item{
    margin: 5px 0;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item div span{
    border: 2px solid #EBEBE6;
    background: transparent;
    height: 44px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 0 auto 2px;
    color: #000;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item .step_bus span{
    background: #294309;
    border-color: #294309;
    color: #fff;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item .step_subway span{
    background: #FF4713;
    border-color: #FF4713;
    color: #fff;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item .step_tram span{
    background: #338B00;
    border-color: #338B00;
    color: #fff;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item .step_funi span{
    background: #338B00;
    border-color: #338B00;
    color: #fff;
}
.content_results .direction-options .list_rutas .direction-info .right .direction-steps .item .step_train span{
    background: #067F8D;
    border-color: #067F8D;
    color: #fff;
}
.content_results .direction-options .list_rutas .direction-info .right .more_info{
    font-size: 12px;
    margin: 5px 10px 0;
    color: #666;
    width: 100%;
}
@media only screen and (min-width: 992px){
    .content_results{
        padding: 40px 10px 20px;
        background: #fff;
        margin-top: -20px;
    }
    .content_results .direction-options {
        margin: 15px 0 0;
    }
    .content_results .direction-options .list_rutas{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .content_results .direction-options .list_rutas .direction-info{
        margin-bottom: 15px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
    .content_results .direction-options .list_rutas{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .content_results .direction-options .list_rutas .direction-info{
        width: 49.5%;
        margin-bottom: 15px;
    }
}
@media only screen and (max-width: 330px){
    .content_results .direction-options .list_rutas .direction-info .left,
    .content_results .direction-options .list_rutas .direction-info .right{
        width: 100%;
    }
}

/*PÁGINA DETALLE PLANIFICADOR*/
.section_detail_rute{
    margin-top: 60px;
    position: relative;
}
.section_detail_rute .row.reverse > div{
    position: initial;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}
.section_detail_rute .mapa_rute{
    margin: 0 -15px 0;
    /*padding-top: 105px;*/
    margin-top: -25px;
}
.section_detail_rute .mapa_rute > div{
    width: 100%;
    height: 215px;
}
.section_detail_rute .mapa_rute > div button.gm-control-active.gm-fullscreen-control{
    margin-top: 35px!important;
}
.section_detail_rute .cabecera_detail_rute{
    background: #333;
    padding: 20px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;
    color: #fff;
    z-index: 2;
    /*position: absolute;
    top: 0;
    left: 0;
    right: 0;*/
}
.section_detail_rute .cabecera_detail_rute .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.section_detail_rute .cabecera_detail_rute .header .title{
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
    text-align: left;
}
.section_detail_rute .cabecera_detail_rute .header .time{
    font-size: 22px;
    font-weight: 900;
    margin: 0;
    min-width: 100px;
    text-align: right;
}
.section_detail_rute .cabecera_detail_rute .select_time{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.section_detail_rute .cabecera_detail_rute .select_time .button_select{
    border: none;
    background: transparent;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 300;
    margin: 0;
    cursor: pointer;
}
.section_detail_rute .cabecera_detail_rute .select_time .button_select span.material-icons{
    margin: 0 5px;
    background: #000;
    border-radius: 8px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}
.section_detail_rute .cabecera_detail_rute .select_time .range_hours{
    font-size: 16px;
    margin: 0 5px;
}
.section_detail_rute .steps_rute .content_indications{
    background: #fff;
    margin: 20px 30px 20px -15px;
    padding: 20px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.section_detail_rute .steps_rute .content_indications .list{
    padding: 0;
    margin: 0;
    list-style: none;
}
.section_detail_rute .steps_rute .content_indications .list .item{
    display: flex;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark{
    width: 40px;
    background: transparent;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark.icon_end{
    justify-content: space-between;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark span.material-icons{
    border: 2px solid #EBEBE6;
    background: transparent;
    height: 44px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 0 auto 2px;
    color: #000;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark.icon_bus span.material-icons{
    border: 2px solid #294309;
    background: #294309;
    color: #fff;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark.icon_metro span.material-icons{
    background: #FF4713;
    border-color: #FF4713;
    color: #fff;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark.icon_tram span.material-icons{
    background: #338B00;
    border-color: #338B00;
    color: #fff;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark.icon_funi span.material-icons{
    background: #338B00;
    border-color: #338B00;
    color: #fff;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark.icon_train span.material-icons{
    background: #067F8D;
    border-color: #067F8D;
    color: #fff;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark .line{
    height: calc(100% - 60px);
    border-left: 2px solid #067F8D;
    margin: 0;
    position: relative;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark .line .circle{
    position: absolute;
    top: -7px;
    left: -7px;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark .line .circle_complet{
    position: absolute;
    bottom: -7px;
    left: -7px;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark .line .circle_complet img{
    background: #fff;
}
.section_detail_rute .steps_rute .content_indications .list .item .mark.icon_walk .line{
    border-left: 4px dotted #067F8D;
}
.section_detail_rute .steps_rute .content_indications .list .item .content{
    width: calc(100% - 80px);
    padding: 20px 0;
    margin: 0 20px;
    border-bottom: 2px dotted #B6B6B6;
    position: relative;
}
.section_detail_rute .steps_rute .content_indications .list .item:last-child .content{
    border-bottom: none;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .title{
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 15px;
    margin-right: 10px;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .description{
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 10px;
    color: #666;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .time{
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #067F8D;
    border-radius: 8px;
    background: #fff;
    padding: 5px;
    position: absolute;
    top: 10px;
    right: -60px;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .time.active{
    background: #067F8D;
    color: #fff;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .header .line{
    /*margin-left: 10px;*/
    background: #EBEBE6;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .header .line img{
    position: absolute;
    top: -8px;
    right: -10px;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .subtitle{
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 5px;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .link{
   display: flex;
   align-items: center;
   font-size: 12px;
   font-weight: 500;
   color: #666;
   margin: 5px 10px;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .link img{
    margin-left: 10px;
}
.section_detail_rute .steps_rute .content_indications .list .item .content .button_select{
   margin: 5px 10px 20px 0;
   background: #666;
   color: #fff;
   padding: 5px 10px;
   font-weight: 500;
   font-size: 12px;
   display: inline-flex;
   align-items: center;
   border: none;
   border-radius: 8px;
}
.section_detail_rute .steps_rute .section_save_rute{
    background: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    min-height: 82px;
    box-shadow: 0px -5px 5px rgba(0,0,0,0.05);
    padding: 20px;
}
.section_detail_rute .steps_rute .section_save_rute .button_save{
    width: 100%;
    border-radius: 8px;
    background: #D20A11;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 10px;
    min-height: 46px;
    border: 1px solid transparent;
}
.section_detail_rute .steps_rute .section_save_rute .button_save:hover,
.section_detail_rute .steps_rute .section_save_rute .button_save:focus{
    background: #fff;
    color: #D20A11;
    border-color: #D20A11;
}
.section_detail_rute .cabecera_detail_rute .back_planificador{
    display: none;
}
.section_detail_rute .row.reverse .mobile{
    display: block;
}
.section_detail_rute .row.reverse .steps_rute .cabecera_detail_rute{
    display: none;
}
@media only screen and (min-width: 992px){
    .section_detail_rute .row.reverse .mobile{
        display: none;
    }
    .section_detail_rute .row.reverse .steps_rute .cabecera_detail_rute{
        display: block;
    }
    .section_detail_rute .row.reverse > div.steps_rute{
        max-height: calc(100vh - 100px);
        overflow-y: auto;
    }
    .section_detail_rute .steps_rute .content_indications .list .item .content .time{
        right: -70px;
    }
}
@media only screen and (min-width: 975px){
    .section_detail_rute .row.reverse,
    .section_linea_horarios .row.reverse{
        flex-direction: row-reverse;
    }
    .section_detail_rute{
        margin-top: 104px;
    }
    .section_detail_rute .row.reverse > div.steps_rute{
        position: relative;
        background: linear-gradient(to right, #fff, transparent);
    }
    .section_detail_rute .cabecera_detail_rute{
        position: initial;
        padding-top: 65px;
        text-align: left;
    }
    .section_detail_rute .cabecera_detail_rute .back_planificador{
        display: inline-flex;
        background: #fff;
        padding: 2px;
        border-radius: 100%;
        color: #D20A11;
        text-decoration: none;
        margin-bottom: 10px;
    }
    .section_detail_rute .cabecera_detail_rute .back_planificador span{
        font-size: 20px;
    }
    .section_detail_rute .cabecera_detail_rute .back_planificador:hover, .section_detail_rute .cabecera_detail_rute .back_planificador:focus{
        background: #D20A11;
        color: #fff;
    }
    .section_detail_rute .mapa_rute{
        padding-top: 0px;
    }
    .section_detail_rute .mapa_rute > div{
        height: 100%;
    }
    .section_detail_rute .steps_rute .content_indications{
        margin: 0px 30px 0px 0px;
        padding-top: 30px;
        padding-right: 10px;
        padding-bottom: 40px;
        box-shadow: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .section_detail_rute .steps_rute .section_save_rute{
        position: initial;
        margin-top: -25px;
    }
    /*.section_detail_rute .steps_rute .content_indications .list{
        height: calc(100vh - 375px);
        overflow: auto;
    }*/
    .section_detail_rute .steps_rute .content_indications .list .item{
        width: calc(100% - 20px);
    }
}
@media only screen and (max-width: 974px){
    .section_detail_rute > .container{
        width: 100%;
    }
}

/*PÁGINA LÍNEAS GUARDADAS*/
.section_content_lineas.section_favoritos{
    margin: 50px auto;
}

/*PÁGINA LINEAS*/
.cabecera_lineas{
    background: #333;
    padding: 80px 0px 20px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    color: #fff;
}
.lineas_horarios .list_items{
    padding: 0;
    margin: 30px 0 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    padding-bottom: 10px;
}
.lineas_horarios .list_items li{
    width: 80px;
    min-width: 80px;
}
.lineas_horarios .list_items .link{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-decoration: none;
}
.lineas_horarios .list_items .link span{
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
}
.lineas_horarios .list_items .link span.image{
    margin-bottom: 5px;
    background: #000;
    padding: 2px;
    border-radius: 8px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
    border: 1px solid transparent;
}
.lineas_horarios .list_items .link span.image .icon{
    font-size: 30px;
}
.lineas_horarios .list_items .link.active span.image,
.lineas_horarios .list_items .link.active:focus span.image{
    background: #D20A11;
}
.lineas_horarios .list_items .link:hover span.image,
.lineas_horarios .list_items .link:focus span.image{
    border-color: #D20A11;
    color: #D20A11;
    background: #fff;
}
.lineas_horarios .list_items .link.active:focus span.image .icon{
    color: #fff;
}
.lineas_horarios .list_items .link:hover span.image .icon,
.lineas_horarios .list_items .link:focus span.image .icon{
    color: #D20A11;
}
.lineas_horarios .list_items .link span.image > img{
    width: 22px;
}
.lineas_horarios .list_items .link span.image .normal,
.lineas_horarios .list_items .link.active:focus span.image .normal{
    display: block;
}
.lineas_horarios .list_items .link span.image .hover,
.lineas_horarios .list_items .link.active:focus span.image .hover{
    display: none;
}
.lineas_horarios .list_items .link:hover span.image .normal,
.lineas_horarios .list_items .link:focus span.image .normal{
    display: none;
}
.lineas_horarios .list_items .link:hover span.image .hover,
.lineas_horarios .list_items .link:focus span.image .hover{
    display: block;
}



.section_content_lineas{
    margin: 10px auto 50px;
}
.section_content_lineas .group_lineas .title{
    margin: 30px 0 20px;
    font-size: 20px;
    font-weight: 700;
}
.group_lineas .section_collapse .item_head button.collapsed{
    background: #fff;
    border-left: 8px solid #067F8D;
}
.group_lineas .section_collapse .item_head button{
    background: #067F8D;
    border-left: 8px solid #434343;
    font-size: 18px;
    font-weight: 400;
}
.group_lineas .section_collapse .item_head .text{
    padding-left: 0;
}
.group_lineas .section_collapse .item_head .text:before{
    content: none;
}
.group_lineas .section_collapse .item_head button.collapsed .arrow{
    color: #067F8D;
}
.group_lineas .section_collapse .content_body{
    background: rgba(255,255,255,0.5);
    padding: 15px;
    position: initial;
    width: 100%;
}
.list_lineas{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.list_lineas .items{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    padding: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin: 5px 15px 5px 0;
}
.list_lineas .items a{
    color: #000;
    text-decoration: none;
}
.list_lineas .items a:hover, .list_lineas .items a:focus{
    text-decoration: none;
}
.list_lineas .item a:hover, .list_lineas .items .pre_title{
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 10px;
    color: #000;
}
.list_lineas .item a:hover, .list_lineas .items .title_linea{
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding-right: 40px;
}
.list_lineas .item a:hover, .list_lineas .items .title_linea span{
    color: #D20A11;
    margin-right: 15px;
}
.list_lineas .item a:hover, .list_lineas .items .linea{
    margin: 0;
    padding: 5px 10px;
    border: 1px solid #067F8D;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 8px;
    right: -15px;
    background: #fff;
    min-width: 64px;
    text-align: center;
}
.list_lineas .item a:hover, .list_lineas .items .linea img{
    position: absolute;
    width: 30px;
    top: 2px;
    left: -18px;
}
.type_transporte{
    margin:0;
    padding: 0;
    list-style: none;
}
.type_transporte .subtitle{
    font-size: 18px;
    font-weight:400;
    margin: 20px 0 10px;
    border-left: 3px solid #D20A11;
    padding-left: 10px;
}

@media only screen and (min-width: 992px){
    .cabecera_lineas{
        padding-top: 200px;
    }
    .list_lineas .items{
        margin-right: 30px;
        width: calc(33.3% - 30px);
    }
    .lineas_horarios .list_items{
        justify-content: center;
    }
    .group_lineas .section_lineas_favoritas .lineas_list .item,
    .group_lineas .list_lineas .items{
        width: calc(50% - 30px)!important;
    }
    .group_lineas .section_collapse{
        margin: 0 20px 0 0;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
    .list_lineas .items{
        margin-right: 30px;
        width: calc(50% - 30px);
    }
}

/*PÁGINA DE ALERTAS*/
.section_content_alerts{
    padding-top: 60px;
    margin-bottom: 50px;
}
.section_content_alerts .title{
    margin: 40px 0 10px;
    font-size: 20px;
    font-weight: 700;
}
.section_content_alerts .list_alerts{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.section_content_alerts .list_alerts .item_alerta{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    padding: 20px;
    border-left: 15px solid #40808d;
    margin: 30px 0 0;
    position: relative;
    width: 100%;
    margin: 20px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.section_content_alerts .list_alerts .item_alerta.info{
    border-color: #D20A11;
}
.section_content_alerts .list_alerts .item_alerta .icono_notificacion{
    width: 34px;
    position: absolute;
    top: -15px;
    left: 10px;
}
.section_content_alerts .list_alerts .item_alerta .time{
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 10px;
}
.section_content_alerts .list_alerts .item_alerta .text{
    display: flex;
    flex-direction: column-reverse;
}
.section_content_alerts .list_alerts .item_alerta .subtitle{
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
}
.section_content_alerts .list_alerts .item_alerta .icon{
    font-size: 35px;
    color: #D20A11;
}
.section_content_alerts .list_alerts .item_alerta .alert_link{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.section_content_alerts .text_description{
   margin: 40px 0 10px;
}
@media only screen and (min-width: 992px){
    .section_content_alerts{
        padding-top: 160px;
    }
    .section_content_alerts .list_alerts{
        margin: 0 -10px;
    }
    .section_content_alerts .list_alerts .item_alerta{
        margin: 30px 10px 10px;
        width: calc(50% - 20px);
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
    .section_content_alerts .list_alerts{
        margin: 0 -10px;
    }
    .section_content_alerts .list_alerts .item_alerta{
        margin: 30px 10px 10px;
        width: calc(50% - 20px);
    }
}
.button_confi{
    background: #D20A11;
    border-radius: 25px;
    border: none;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 100px;
    right: 15px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    width: auto;
}
.button_confi .text{
    margin-left: 10px;
    margin-right: 10px;
    display: none;
}
.button_confi:hover .text, .button_confi:focus .text{
    display: block;
}
@media only screen and (min-width: 992px){
    .button_confi{
        top: 130px;
        bottom: auto;
    }
}

/*PÁGINA MAPAS*/
.cabecera_mapas{
    min-height: 250px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    position: relative;
    background: url('../images/03_ilustracion_mapas_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cabecera_mapas .title{
    color: #fff;
    position: relative;
    text-align: center;
    padding: 60px 40px 10px;
    font-size: 24px;
    font-weight: 400;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
}
.cabecera_mapas .subtitle{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
    padding: 0 5%;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
    position: relative;
}
@media only screen and (min-width: 992px){
    .cabecera_mapas{
        min-height: 200px;
        margin-top: 100px;
    }
    .cabecera_mapas .title{
        padding: 50px 50px 0px;
    }
}

.content_body_mapas{
    margin-bottom: 50px;
}
.content_body_mapas .list_maps{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.content_body_mapas .mapas_banners{
    margin: 20px 0;
}
.content_body_mapas .mapas_banners .list_maps .item{
    border-radius: 8px;
    min-height: 180px;
    background: #067F8D;
    color: #fff;
    position: relative;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    width: 100%;
    margin: 0 0 20px;
}
.content_body_mapas .list_maps .item .link{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.content_body_mapas .mapas_banners .list_maps .item .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    z-index: 1;
}
.content_body_mapas .mapas_banners .list_maps .item .icon{
    position: absolute;
    bottom: -25px;
    right: 10px;
    z-index: 1;
}
.content_body_mapas .mapas_banners .list_maps .item .image{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 0;
    max-width: 330px;
    width: 100%;
}
.content_body_mapas .mapas_cards{
    margin: 40px 0 10px;
}
.content_body_mapas .mapas_cards .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
}
.content_body_mapas .mapas_cards .list_maps .item{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    padding: 10px 20px;
    border-left: 8px solid #40808d;
    margin: 0 0 20px;
    position: relative;
    width: 100%;
}
.content_body_mapas .mapas_cards .list_maps .item.big{
    display: flex;
    align-items: center;
    min-height: 96px;
}
.content_body_mapas .mapas_cards .list_maps .item.big:before{
    content: "";
    background: url("../images/Icon_bus_cut.png");
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 40%;
    height: 70px;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
}
.content_body_mapas .mapas_cards .list_maps .item.big .image{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    border-radius: 8px;
    margin-right: 10px;
    background: #fff;
    z-index: 1;
}
.content_body_mapas .mapas_cards .list_maps .item.big .image img,
.content_body_mapas .mapas_cards .list_maps .item.medium .image img{
    max-width: 100%;
}
.content_body_mapas .mapas_cards .list_maps .item.medium{
    display: flex;
    align-items: center;
    min-height: 81px;
    width: calc(50% - 10px);
}
.content_body_mapas .mapas_cards .list_maps .item.medium .image{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    border-radius: 8px;
    margin-right: 10px;
    background: #fff;
    z-index: 1;
}
.content_body_mapas .mapas_cards .list_maps .item .subtitle{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    z-index: 1;
}
.content_body_mapas .mapas_cards .list_maps .item .icon{
    position: absolute;
    bottom: -18px;
    right: 10px;
    z-index: 1;
    width: 35px;
}
@media only screen and (min-width: 768px){
    .content_body_mapas{
        margin: 50px auto;
        max-width: 800px;
    }
    .content_body_mapas .mapas_banners .list_maps .item .title{
        text-align: center;
    }
    .content_body_mapas .mapas_banners .list_maps .item .image{
        right: auto;
    }
    .content_body_mapas .mapas_cards .list_maps .item{
        width: calc(50% - 10px);
    }
    .content_body_mapas .mapas_cards .list_maps .item.medium{
        width: calc(25% - 15px);
    }
}

/*PÁGINA AYUDA Y CONTACTO*/
.cabecera_contacto{
    min-height: 250px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    position: relative;
    background: url('../images/05_ilustracion_ayuda_contacto_20211111_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cabecera_contacto .title{
    color: #fff;
    position: relative;
    text-align: center;
    padding: 60px 20px 0px;
    font-size: 24px;
    font-weight: 400;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
}
@media only screen and (min-width: 992px){
    .cabecera_contacto{
        min-height: 200px;
        margin-top: 100px;
    }
    .cabecera_contacto .title{
        padding: 50px 50px 0px;
    }
}
.section_contacto{
    padding: 40px 0;
    background: #fff;
    margin-top: -20px;
}
.section_preguntas_frecuentes{
    position: relative;
    margin-bottom: 40px;
}
.section_preguntas_frecuentes .title,
.section_contacta .title,
.section_office .title,
.section_contacto .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
}
@media only screen and (min-width: 768px){
    .section_contacto{
        padding: 70px 0 40px;
    }
    .section_contacto .container_desktop{
        margin: 0 auto;
        max-width: 800px;
    }
    .section_preguntas_frecuentes .section_collapse{
        margin: 0 -10px;
    }
    .section_preguntas_frecuentes .section_collapse .item{
        margin: 0 10px 10px;
        /*width: calc(50% - 20px);*/
    }
    .section_preguntas_frecuentes .section_collapse .content_body{
        position: initial;
        width: 100%;
    }
}
.section_contacta{
    margin-bottom: 40px;
}
.section_contacta .list_contacta{
    margin: 0 -15px 0 auto;
    width: 90%;
    padding: 40px;
    list-style: none;
    background: #067F8D;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    color: #fff;
}
.section_contacta .list_contacta .item{
    position: relative;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section_contacta .list_contacta .item .icon{
    background: #fff;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid #067F8D;
    position: absolute;
    left: -55px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section_contacta .list_contacta .item p{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.section_contacta .list_contacta .item p.tel{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.section_contacta .list_contacta .item p a{
    color: #fff;
    text-decoration:none;
}
.section_contacta .list_contacta .item p a:hover, .section_contacta .list_contacta .item p a:focus{
    text-decoration: underline;
}
.section_contacta .list_contacta .rrss{
    display: flex;
    align-items: center;
    justify-content: center;
}
.section_contacta .list_contacta .rrss .link{
    width: 38px;
    height:38px;
    background: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 0px 5px rgba(0,0,0,0.4);
    margin: 0 10px;
    padding: 10px;
}
.section_contacta .list_contacta .rrss .link img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
@media only screen and (min-width: 768px){
    .section_contacta.page .list_contacta{
        width: 100%;
        border-radius: 8px;
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        padding: 0 40px 20px;
        justify-content: space-between;
        margin: 0 auto;
    }
    .section_contacta.page .list_contacta .item{
        margin: -15px 0 10px;
        width: 33.3%;
        align-items: center;
    }
    .section_contacta.page .list_contacta .item.text{
        align-items: flex-start;
    }
    .section_contacta.page .list_contacta .item .icon{
        position: initial;
        margin: 0 auto 20px;
    }
    .section_contacta.page .list_contacta .rrss{
        width: 100%;
        max-width: 500px;
        border-top: 1px solid;
        margin: 50px auto 0;
        padding-top: 20px;
    }
}


/*PÁGINA DESARROLLADORES*/
.cabecera_desarrolladores{
    min-height: 250px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    position: relative;
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cabecera_desarrolladores .title{
    color: #fff;
    position: relative;
    text-align: center;
    padding: 60px 20px 0px;
    font-size: 24px;
    font-weight: 400;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
}
@media only screen and (min-width: 992px){
    .cabecera_desarrolladores{
        min-height: 200px;
        margin-top: 100px;
    }
    .cabecera_desarrolladores .title{
        padding: 50px 50px 0px;
    }
}
/*.section_desa{
    margin: 40px auto;
}*/
@media only screen and (min-width: 768px){
    .section_desa{
        max-width: 800px;
    }
}

/*PÁGINA LÍNEAS GUARDADAS*/
.cabecera_lineas_save{
    min-height: 250px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    position: relative;
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cabecera_lineas_save .title{
    color: #fff;
    position: relative;
    text-align: center;
    padding: 60px 20px 0px;
    font-size: 24px;
    font-weight: 400;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
}
@media only screen and (min-width: 992px){
    .cabecera_lineas_save{
        min-height: 200px;
        margin-top: 100px;
    }
    .cabecera_lineas_save .title{
        padding: 50px 50px 0px;
    }
}
@media only screen and (max-width: 992px){
    .app_favoritos.cabecera_lineas_save{
        padding-top: 80px;
    }
}

/*PÁGINA TÍTULOS Y TARIFAS*/
.cabecera_titulos_tarifas{
    min-height: 250px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    position: relative;
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cabecera_titulos_tarifas .title{
    color: #fff;
    position: relative;
    text-align: center;
    padding: 60px 20px 0px;
    font-size: 24px;
    font-weight: 400;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
}
@media only screen and (min-width: 992px){
    .cabecera_titulos_tarifas{
        min-height: 200px;
        margin-top: 100px;
    }
    .cabecera_titulos_tarifas .title{
        padding: 50px 50px 0px;
    }
}
.section_titulos_tarifas {
    padding: 40px 0;
    background: #fff;
    margin-top: -20px;
}
.section_titulos_tarifas .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
}
.section_titulos_tarifas .select_linea{
    max-width: 100%;
}
@media only screen and (min-width: 768px){
    .section_titulos_tarifas > .container{
        max-width: 800px;
    }
    /*.section_titulos_tarifas .section_collapse{
        margin: 0 -10px;
    }*/
    /*.section_titulos_tarifas .section_collapse .item{
        margin: 0 10px 10px;
        width: calc(50% - 20px);
    }*/
    .section_titulos_tarifas .section_collapse .content_body{
        position: initial;
        width: 100%;
    }
}
@media only screen and (min-width: 992px){
    .section_titulos_tarifas{
        padding: 70px 0 40px;
    }
}

/*PÁGINAS POLÍTICAS / ACCESIBILIDAD */
.cabecera_common{
    min-height: 250px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    position: relative;
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cabecera_common .title{
    color: #fff;
    position: relative;
    text-align: center;
    padding: 60px 20px 0px;
    font-size: 24px;
    font-weight: 400;
    text-shadow: 2px 0px 2px rgba(0,0,0,0.2);
}
@media only screen and (min-width: 992px){
    .cabecera_common{
        min-height: 200px;
        margin-top: 100px;
    }
    .cabecera_common .title{
        padding: 50px 50px 0px;
    }
}
.section_page_common{
    background: #fff;
    margin-top: -20px;
    padding: 40px 0;
}
.section_page_common .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
}
.section_page_common p{
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 20px;
}
.section_page_common img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
}
.section_page_common ul{
    margin: 0 0 20px;
}
.section_page_common li p,
.section_page_common li{
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: 400;
}
.section_page_common a{
    color: #D20A11;
}
.section_page_common a:hover, .section_page_common a:focus{
    color: #D20A11;
}
@media only screen and (min-width: 992px){
    .section_page_common{
        padding: 70px 0 40px;
    }
    .section_page_common > .container{
        max-width: 800px;
    }
}

/*PÁGINA LISTADO OFICINAS*/
.section_list_offices{
    padding: 100px 0 40px;
    background: #fff;
}
.section_list_offices .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
}
.section_list_offices .section_collapse .item .link_more_static{
    margin-bottom: 0px;
}
.section_list_offices .section_collapse .item_head button .text:before{
    background: url('../images/icons/icon_office_white.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.section_list_offices .section_collapse .item_head button.collapsed .text:before{
    background: url('../images/icons/icon_office_black.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
@media only screen and (min-width: 992px){
    .section_list_offices{
        padding: 200px 0 40px;
    }
    .section_list_offices > .container{
        max-width: 800px;
    }
    .section_list_offices .section_collapse {
        margin: 0 -10px;
    }
    /*.section_list_offices .section_collapse .item {
        margin: 0 10px 10px;
        width: calc(50% - 20px);
    }*/
    .section_list_offices .section_collapse .content_body{
        position: initial;
        width: 100%;
    }
}

/*PÁGINA DETALLE LINEA*/
.section_detail_line{
    margin-top: 100px;
    position: relative;
}
.section_detail_line .mapa_linea > div{
    height: 100%;
    min-height: 250px;
}
.section_detail_line .link_more_static{
    text-align: left;
}
@media only screen and (max-width: 991px){
    .section_detail_line{
        margin-top: 60px;
        max-width: 100%!important;
    }
    .section_detail_line .row.reverse{
        flex-direction: column-reverse;
    }
    .section_detail_line.ocultar_mapa .mapa_linea {
        display: none;
        margin: 0;
    }
    .section_detail_line.ocultar_mapa .cabecera_detail_linea{
        position: relative;
    }
    .section_detail_line .parada_detalle.cabecera_detail_linea{
        display: none;
    }
    .section_detail_line .cabecera_detail_linea{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        padding: 20px;
    }
    .section_detail_line .mapa_linea{
        margin-top: 145px;
        display: block;
    }
    .content_tabs_line .section_linea_horarios .list_horarios{
        margin: 20px 30px 0 0;
        border-top-right-radius: 25px;
    }
}
.section_detail_line .row > div{
    padding: 0;
    position: initial;
}
.cabecera_detail_linea{
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    position: relative;
    background: url('../images/04_ilustracion_fondo_filtros_20211011_v01.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 65px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
}
.cabecera_detail_linea .list_anclas{
    padding: 20px 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.cabecera_detail_linea .list_anclas li{
    width: 33.3%;
}
.cabecera_detail_linea .list_anclas .link{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-decoration: none;
}
.cabecera_detail_linea .list_anclas .link{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-decoration: none;
}
.cabecera_detail_linea .list_anclas .link span{
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
}
.cabecera_detail_linea .list_anclas .link span.image{
    margin-bottom: 5px;
    background: #000;
    padding: 2px;
    border-radius: 8px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
    border: 1px solid transparent;
}
.cabecera_detail_linea .list_anclas .link.active span.image,
.cabecera_detail_linea .list_anclas .link.active:focus span.image{
    background: #D20A11;
}
.cabecera_detail_linea .list_anclas .link:hover span.image,
.cabecera_detail_linea .list_anclas .link:focus span.image{
    border-color: #D20A11;
    color: #D20A11;
    background: #fff;
}
.cabecera_detail_linea .list_anclas .link:hover span.image .icon,
.cabecera_detail_linea .list_anclas .link:focus span.image .icon{
    color: #D20A11;
}
.cabecera_detail_linea .list_anclas .link span.image img{
    width: 24px;
    height: 24px;
}
.cabecera_detail_linea .list_anclas .link span.image .normal,
.cabecera_detail_linea .list_anclas .link.active:focus span.image .normal{
    display: block;
}
.cabecera_detail_linea .list_anclas .link span.image .hover,
.cabecera_detail_linea .list_anclas .link.active:focus span.image .hover{
    display: none;
}
.cabecera_detail_linea .list_anclas .link:hover span.image .normal,
.cabecera_detail_linea .list_anclas .link:focus span.image .normal{
    display: none;
}
.cabecera_detail_linea .list_anclas .link:hover span.image .hover,
.cabecera_detail_linea .list_anclas .link:focus span.image .hover{
    display: block;
}
.section_detail_line .cabecera_detail_linea .back_planificador{
    display: none;
}
@media only screen and (min-width: 975px){
    .section_detail_line .cabecera_detail_linea .back_planificador{
        display: flex;
        justify-content: center;
        background: #fff;
        padding: 2px;
        border-radius: 100%;
        color: #D20A11;
        text-decoration: none;
        margin-bottom: 10px;
        width: 25px;
        height: 25px;
    }
    .section_detail_line .cabecera_detail_linea .back_planificador span{
        font-size: 20px;
    }
    .section_detail_line .cabecera_detail_linea  .back_planificador:hover, .section_detail_line .cabecera_detail_linea .back_planificador:focus{
        background: #D20A11;
        color: #fff;
    }
}
.section_linea_horarios .mapa_linea{
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}
.section_linea_horarios .mapa_linea > div {
    width: 100%;
    height: 250px;
}
.section_linea_horarios {
    position: initial;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}
.section_linea_horarios .list_horarios{
    background: #fff;
    margin: -20px 30px 0px 0px;
    padding: 50px 0px 0px 20px;
    border-top-right-radius: 0px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.section_linea_horarios .list_horarios .linea_horario{
    width: calc(100% - 50px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section_linea_horarios .list_horarios .linea_horario .title{
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    color: #000;
    display: flex;
    align-items: center;
}
.section_linea_horarios .list_horarios .linea_horario .title .line {
    background: #EBEBE6;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    margin: 0 0 0 10px;
}
.section_linea_horarios .list_horarios .linea_horario .title .line img {
    position: absolute;
    top: -6px;
    right: -8px;
}
.section_linea_horarios .list_horarios .linea_horario .title .numero_linea{
    padding: 2px 5px;
    background: #EBEBE6;
    border-radius: 8px;
    margin-left: 10px;
}
.section_linea_horarios .list_horarios .linea_horario .info_linea{
    display: flex;
    align-items: center;
    margin: 10px 0 0;
    position: relative;
}
.section_linea_horarios .list_horarios .linea_horario .info_linea span{
    color: #D20A11;
    margin-right: 10px;
    padding-bottom: 15px;
}
.section_linea_horarios .list_horarios .linea_horario .info_linea .text{
    border-bottom: 2px dotted #B6B6B6;
    width: 100%;
    padding-bottom: 15px;
}
.section_linea_horarios .list_horarios .linea_horario .info_linea .text .subtitle{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.section_linea_horarios .list_horarios .linea_horario .info_linea .text .horario{
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.section_linea_horarios .list_horarios .linea_horario .info_linea .text .see_map{
    background: transparent;
    border: none;
    font-size: 12px;
    text-decoration: underline;
    padding: 5px 0;
    color: #D20A11;
}
.section_linea_horarios .list_horarios .linea_horario .info_linea .text .see_map:hover, .section_linea_horarios .content_horarios .list_horarios .linea_horario .info_linea .text .see_map:focus{
    color: #000;
}
.section_linea_horarios .list_horarios .linea_horario .button_change_direccion{
    border: none;
    width: 45px;
    height: 45px;
    background: #D20A11;
    border-radius: 100%;
    position: absolute;
    right: -70px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}
.section_linea_horarios .list_horarios .linea_horario .button_change_direccion:hover, .section_linea_horarios .content_horarios .list_horarios .linea_horario .button_change_direccion:focus{
    background: #000;
}
.section_linea_horarios .list_horarios .linea_horario .button_change_direccion img{
    filter: saturate(1) brightness(0.1) invert(1);
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas{
    padding: 30px 0;
    margin: 0;
    list-style: none;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item{
    display: flex;
    position: relative;
    background: #fff;
    padding: 20px 10px 20px 0;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .mark_line{
    width: 40px;
    display: flex;
    justify-content: center;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item.fin{
    padding: 10px 0;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .mark_line img{
    position: absolute;
    top: 0px;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .mark_line .line{
    width: 2px;
    height: calc(100% + 40px);
    background: #067F8D;
    margin: -20px 0;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .mark_line .line:before{
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #067F8D;
    background: #fff;
    position: absolute;
    top: 22px;
    left: -4px;
    border-radius: 100%;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .content{
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .content .button_parada{
    text-align: left;
    border: none;
    background: transparent;
    font-size: 12px;
    font-weight: 400;
    padding: 0px;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .content .link{
    font-size: 12px;
    font-weight: 500;
    padding: 10px 0 0;
    color: #666;
    text-decoration: none;
    display: none;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .content .text_end{
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .content .link:hover, .section_linea_horarios .content_horarios .list_horarios .paradas_linea .list_paradas .item .content .link:focus{
    text-decoration: none;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .content .link img{
    margin-left: 10px;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .times{
    display: flex;
    flex-direction: column;
    align-items: end;
    position: absolute;
    right: -15px;
    top: 15px;
    display: none;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .times .first_time{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: #067F8D;
    padding: 3px 8px;
    border-radius: 8px;
    margin-bottom: 5px;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item .times .second_time{
    font-size: 10px;
    font-weight: 400;
    color: #000;
    background: #fff;
    border: 1px solid #067F8D;
    padding: 3px 8px;
    border-radius: 8px;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item.active{
    background: #EBEBE6;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item.active .content .button_parada{
    font-size: 16px;
    font-weight: 700;
    padding-right: 80px;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item.active .content .link{
    display: block;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item.active .times{
    display: flex;
}
.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item.active .mark_line .line:before{
    border: 2px solid #067F8D;
    background: #067F8D;
}

.paradas_linea .list_abc_paradas{
    padding: 0;
    margin: 30px 0;
    list-style: none;
}
.paradas_linea .list_abc_paradas .nivel_1{
    position: relative;
    margin-bottom: 5px;
}
.paradas_linea .list_abc_paradas .nivel_1 .letter{
    position: absolute;
    top: 4px;
    left: 0;
    color: #D20A11;
    font-size: 20px;
    font-weight: 500;
    border-left: 3px solid;
    padding: 0 10px;
    line-height: 1;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2{
    padding: 0 0 0 40px;
    margin: 0;
    list-style: none;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada{
    position: relative;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .button_parada{
    text-align: left;
    border: none;
    background: transparent;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 0px;
    width: 100%;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .link{
    display: none;
    font-size: 12px;
    font-weight: 500;
    padding: 0 15px 20px;
    color: #666;
    text-decoration: none;
    display: none;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .link img{
    margin-left: 10px;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada.active{
    background: #EBEBE6;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada.active .button_parada{
    font-size: 16px;
    font-weight: 700;
    padding: 20px 15px 5px;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada.active .link{
    display: block;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada.active .times{
    display: flex;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .times{
    flex-direction: column;
    align-items: end;
    position: absolute;
    right: -15px;
    top: 15px;
    display: none;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .times .first_time{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: #067F8D;
    padding: 3px 8px;
    border-radius: 8px;
    margin-bottom: 5px;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada .times .second_time{
    font-size: 10px;
    font-weight: 400;
    color: #000;
    background: #fff;
    border: 1px solid #067F8D;
    padding: 3px 8px;
    border-radius: 8px;
}

/*.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada a{
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
    display: block;
    margin: 0;
    color: #000;
    text-decoration: none;
    background: transparent;
}
.paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada a.active, .paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada a:hover, .paradas_linea .list_abc_paradas .nivel_1 .nivel_2 .parada a:focus{
    background: #EBEBE6;
    text-decoration: none;
}*/
.section_linea_horarios .list_horarios .content_text{
    padding: 20px 20px 50px 0;
    font-size: 14px;
    font-weight: 400;
}
.section_linea_horarios .list_horarios .content_text .table th{
    border: none;
}
.section_linea_horarios .section_dowdload_linea{
    background: #067F8D;
    padding: 15px 20px 30px;
    margin-left: -20px;
    margin-right: -30px;
}
.section_linea_horarios .section_dowdload_linea .link{
    background: #034D55;
    border-radius: 8px;
    display: block;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0;
    position: relative
}
.section_linea_horarios .section_dowdload_linea .link .icon{
    position: absolute;
    right: 18px;
}
.section_linea_horarios .section_dowdload_linea .link:hover, .section_linea_horarios .section_dowdload_linea .link:focus{
    background: #000;
}
@media only screen and (max-width: 991px){
    .section_linea_horarios .section_dowdload_linea{
        margin-right: -30px;
    }
}
/*Boton guardar y compartir linea*/
.section_detail_line .section_save_rute{
    background: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    width: 100%;
    min-height: 82px;
    box-shadow: 0px -5px 5px rgba(0,0,0,0.05);
    padding: 20px;
    margin-top: -20px;
    z-index: 1;
    position: relative;
}
.section_detail_line .section_save_rute .button_save{
    width: 100%;
    border-radius: 8px;
    background: #D20A11;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 10px;
    min-height: 46px;
    border: 1px solid transparent;
}
.section_detail_line .section_save_rute .button_share, .section_detail_rute .steps_rute .section_save_rute .button_share {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    padding: 5px;
    background: #fff;
    border: 1px solid #D20A11;
    color: #D20A11;
    margin-left: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    border-radius: 8px;
}
.section_detail_line .section_save_rute .button_save:hover,
.section_detail_line .section_save_rute .button_save:focus{
    background: #fff;
    border-color: #D20A11;
    color: #D20A11;
}
.section_detail_line .section_save_rute .button_share:hover,
.section_detail_line .section_save_rute .button_share:focus,
.section_detail_rute .steps_rute .section_save_rute .button_share:hover,
.section_detail_rute .steps_rute .section_save_rute .button_share:focus{
    background: #D20A11;
    border-color: #D20A11;
    color: #fff;
}
.section_detail_line .section_save_rute .button_share:hover span,
.section_detail_line .section_save_rute .button_share:focus span,
.section_detail_rute .steps_rute .section_save_rute .button_share:hover span,
.section_detail_rute .steps_rute .section_save_rute .button_share:focus span{
    color: #fff;
}
.content_ayuda_contacto {
    padding: 20px;
}
.content_ayuda_contacto .name_transporte{
    margin: 20px 0 10px;
    font-size: 24px;
    font-weight: 500;
    color: #D20A11;
}
.content_ayuda_contacto.section_contacto .list_contacta{
    margin: 0 -20px 0 auto;
}
@media only screen and (min-width: 992px){
    .content_tabs_line{
        /*height: calc(100vh - 100px);*/
        overflow-y: auto;
    }
    .content_tarifas.container{
        max-width: 800px;
    }
    .content_tarifas .cabecera_title{
        background: #fff;
        margin: 40px 20px 20px 0;
        padding: 20px 0 20px 20px;
        border-radius: 25px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
    }
}
@media only screen and (max-width: 991px){
    .section_detail_line .section_save_rute{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10

    }
}
/*MODAL COMPARTIR LÍNEA*/
.modal_bm.modal_share .modal-dialog{
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.modal_bm.modal_share .modal-content{
    padding: 0;
}
.modal_share .modal-content .header_modal{
    background: #EBEBE6;
    padding: 30px 20px 20px;
    border-radius: 8px;
}
.modal_share .modal-content .header_modal .title{
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 5px;
    color: #000;
}
.modal_share .modal-content .header_modal .subtitle{
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 20px;
    color: #000;
}
.modal_share .modal-content .header_modal  .list_rrss{
    padding: 0;
    margin: 30px 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
}
.modal_share .modal-content .header_modal  .list_rrss li{
    width: calc(33.3% - 20px);
}
.modal_share .modal-content .header_modal  .list_rrss .item_rrss{
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
}
.modal_share .modal-content .header_modal  .list_rrss .item_rrss:hover, .modal_share .modal-content .header_modal  .list_rrss .item_rrss:focus{
    text-decoration: none;
}
.modal_share .modal-content .body_modal{
    padding: 30px 20px 40px;
}
.modal_share .modal-content .body_modal .subtitle{
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 10px;
    color: #000;
}
.modal_share .modal-content .body_modal .share_linea{
    display: flex;
}
.modal_share .modal-content .body_modal .text_code{
    color: #B1B1B1;
    font-size: 10px;
    font-weight: 400;
    border: 1px solid #000;
    padding: 10px;
    border-radius: 8px;
    margin: 0 10px 0 0;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    width: 100%;
}
.modal_share .modal-content .body_modal .button_copy{
    background: #D20A11;
    border: 1px solid transparent;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    font-size: 11px;
    font-weight: 700;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}
.modal_share .modal-content .body_modal .button_copy:hover, .modal_share .modal-content .body_modal .button_copy:focus{
    background: #fff;
    color: #D20A11;
    border-color: #D20A11;
}

/*APARTADO DETALLE PARADA*/
.page_detail_parada{
    padding-top: 80px;
}
.content_detail_parada {
    background: #fff;
    margin: 20px 30px 0px 0px;
    padding: 0 20px 20px;
    border-top-right-radius: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.content_detail_parada .cabecera_detail_parada{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 20px;
}
.content_detail_parada .cabecera_detail_parada .title{
    font-size: 24px;
    margin: 0;
    font-weight: 500;
    color: #D20A11;
    display: flex;
    width: calc(100% - 140px);
}
.content_detail_parada .cabecera_detail_parada .title .text{
    padding: 40px 0 0;
}
.content_detail_parada .cabecera_detail_parada .link_more_static{
    width: 130px;
    text-align: right;
    margin: 40px 0 0 auto;
    background: transparent;
	border: none;
}
@media only screen and (min-width: 992px){
    .content_detail_parada.mobile_mapa{
        display: none!important;
    }
}
@media only screen and (max-width: 991px){
    .content_detail_parada.mobile_mapa .container_mapa{
        margin: 0 -20px -20px;
    }
    .content_detail_parada.mobile_mapa .container_mapa > div {
        height: calc(100vh - 290px);
    }
}
.summary_detail_parada{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
}
.summary_detail_parada .title{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 5px;
}
.summary_detail_parada .title_linea{
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}
.summary_detail_parada .title_linea > span{
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 8px;
    background: #EBEBE6;
    margin-left: 10px;
}
.summary_detail_parada .select_date{
    background: #000;
    padding: 10px 15px;
    margin: 10px 0;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    font-size: 12px;
    font-weight: 500;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
}
.summary_detail_parada .select_date > span{
    margin-right: 10px;
    font-size: 20px;
}
.summary_detail_parada .select_date:hover, .summary_detail_parada .select_date:focus{
    color: #D20A11;
    border-color: #D20A11;
    background: #fff;
}
.body_detail_parada{
    position: relative;
}
.body_detail_parada .actions_parada{
    display: flex;
    justify-content: space-around;
}
.body_detail_parada .name_direction{
    text-align: center;
    padding: 10px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: none;
    background: transparent;
    opacity: 0.5;
}
.body_detail_parada .name_direction.active, .body_detail_parada .name_direction:hover, .body_detail_parada .name_direction:focus{
    opacity: 1;
}
.body_detail_parada .name_direction > img{
    margin-right: 15px;
}
.body_detail_parada .change_direction{
    border: none;
    width: 45px;
    height: 45px;
    background: #D20A11;
    border-radius: 100%;
    position: absolute;
    right: -40px;
    top: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}
.body_detail_parada .change_direction:hover, .body_detail_parada .change_direction:focus{
    background: #000;
}
.body_detail_parada .select_time{
    border-top: 2px dotted #666;
    /*border-bottom: 2px dotted #666;*/
    padding: 10px 0;
}
.body_detail_parada .select_time .list_items{
    padding: 0;
    margin: 0 auto;
    list-style: none;
    width: 80%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
}
.body_detail_parada .select_time .list_items.oculto {
    max-height: 300px;
    overflow: hidden;
}
.body_detail_parada .select_time .list_items button{
    width: 100%;
    padding: 10px;
    background: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #EBEBE6;
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.body_detail_parada .select_time .list_items button:hover, .body_detail_parada .select_time .list_items button:focus{
    background: #EBEBE6;
}
.body_detail_parada .select_time .see_more{
    padding: 10px 20px;
    background: #fff;
    color: #D20A11;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #D20A11;
    margin: 20px auto;
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}
.body_detail_parada .select_time .see_more:hover, .body_detail_parada .select_time .see_more:focus{
    background: #000;
    border-color: #000;
    color: #fff;
}
.body_detail_parada .more_info{
    margin: 30px 0 0;
}
.body_detail_parada .more_info .title{
    border-left: 3px solid #D20A11;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 10px;
}
.body_detail_parada .more_info p{
    font-size: 14px;
    font-weight: 400;
}
.body_detail_parada .more_info .content{
    padding: 0 0 0 13px;
    margin: 0;
    list-style: none;
}
.body_detail_parada .more_info .content p{
    margin: 0;
}
.section_detail_parada .cabecera_detail_linea{
    display: none;
}
@media only screen and (min-width: 992px){
    .section_detail_line .content_left{
        max-height: calc(100vh - 100px);
        overflow-y: auto;
    }
    .page_detail_parada {
        padding-top: 150px;
    }
    .content_detail_parada{
        margin: -20px 30px 0 0;
        padding-top: 20px;
        border-radius: 0px;
        max-width: 800px;
    }
    .content_detail_parada .cabecera_detail_parada .link_more_static{
        display: none;
    }
    .content_detail_parada .cabecera_detail_parada .title{
        width: 100%;
    }
    .section_detail_parada .cabecera_detail_linea{
        display: block;
    }
}
@media only screen and (max-width: 374px){
    .content_detail_parada .cabecera_detail_parada .title{
        width: 100%;
    }
}
.other_lineas .subtitle{
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
}
.other_lineas .list_other{
    padding: 0px 0px 10px;
    margin: 0px;
    list-style: none;
    display: inline-flex;
    overflow-x: auto;
    width: 100%;
}
.other_lineas .list_other .item_linea{
    position: relative;
    width: 150px;
    min-width: 150px;
    margin: 10px;
}
.other_lineas .list_other .item_linea .link{
    background: #034D55;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
    position: relative;
    width: calc(100% - 20px);
    min-height: 80px;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
}
.other_lineas .list_other .item_linea .link:hover, .other_lineas .list_other .item_linea .link:focus{
    background: #000;
}
.other_lineas .list_other .item_linea .link .icon{
    margin-bottom: 5px;
    font-size: 25px;
}
.other_lineas .list_other .item_linea .link .number{
    background: #fff;
    border: 1px solid #067F8D;
    padding: 5px 10px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 10px;
    right: -20px;
    border-radius: 8px;
}

/*Página detalle alertas*/
.detail_info_alertas .time{
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.detail_info_alertas .list_lineas .items{
    width: 100%;
    position: relative;
    margin: 5px 0 20px 0;
}
.detail_info_alertas .list_lineas .items > a{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.detail_info_alertas .list_lineas .items .title_linea{
    width: 50%;
    padding-right: 20px;
}
.detail_info_alertas .list_lineas .items .linea{
    position: initial;
    max-width: 50%;
}
.detail_info_alertas .content_info{
    margin: 20px 0 0;
    font-size: 14px;
}
.detail_info_alertas .content_info a{
    color: #D20A11;
    text-decoration: none;
}
.detail_info_alertas .content_info a:hover, .detail_info_alertas .content_info a:focus{
    color: #D20A11;
    text-decoration: underline;
}

@media only screen and (min-width: 768px){
    .detail_info_alertas{
        max-width: 800px;
        margin: 0 auto 50px;
    }
    .detail_info_alertas .list_lineas .items{
        margin-right: 30px;
        width: calc(50% - 30px);
    }
}

/*Planificador mini*/
.section_planificador_ruta.share{
    margin: 0;
    background: none;
    padding: 20px !important;
}

/*Boton Cargando*/
@keyframes ld-flip-h {
	0%,25%,50%,75%,100%{
		animation-timing-function:cubic-bezier(0,0.5,0.5,1)
	}
	0% {
		-webkit-transform:rotateY(0) rotateX(0);
		transform:rotateY(0) rotateX(0)
	}
	50% {
		-webkit-transform:rotateY(180deg) rotateX(0);
		transform:rotateY(180deg) rotateX(0)
	}
	100% {
		-webkit-transform:rotateY(0) rotateX(0);
		transform:rotateY(0) rotateX(0)
	}
}

@-webkit-keyframes ld-flip-h {
	0%,25%,50%,75%,100% {
		animation-timing-function:cubic-bezier(0,0.5,0.5,1)
	}
	0% {
		-webkit-transform:rotateY(0) rotateX(0);
		transform:rotateY(0) rotateX(0)
	}
	50% {
		-webkit-transform:rotateY(180deg) rotateX(0);
		transform:rotateY(180deg) rotateX(0)
	}
	100% {
		-webkit-transform:rotateY(0) rotateX(0);
		transform:rotateY(0) rotateX(0)
	}
}

.button_status {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.button_status .background {
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1011;
    background-color: rgba(0,0,0,0.85) !important;
}
.button_status .image {
    -webkit-animation: ld-flip-h 1s infinite;
    animation: ld-flip-h 1s infinite;
    animation-duration: 3s;
    width: 140px;
}
.button_status .text {
    color: #FFF;
    margin-top: 10em;
    margin-left: -7em;
}

/*Tabla cookies*/
.bipo_contenedortabla .cookie_desktop {
    font-size: 13px;
    margin: 0 20px 40px;
}
.bipo_contenedortabla .cookie_desktop th {
    border-bottom: 1px solid #ebebe6;
    text-transform: uppercase;
    font-size: 12px;
    padding: 15px;
}
.bipo_contenedortabla .cookie_desktop td {
    border-bottom: 1px solid #ebebe6;
    padding: 15px 7px !important;
    vertical-align: top;
    padding: 15px;
}
.bipo_contenedortabla .cookie_desktop tbody tr:hover,
.bipo_contenedortabla .cookie_desktop tbody tr:focus {
    background: #ebebe6;
}
.bipo_contenedortabla .cookie_desktop tbody tr:hover th:first-child,
.bipo_contenedortabla .cookie_desktop tbody tr:focus th:first-child {
    border-left: 8px solid #d20a11;
}
.bipo_contenedortabla .bipo_contenedor_accordeon {
    display: none;
}

.bipo_contenedortabla .bipo_contenedor_accordeon {
    margin: 0px 20px 40px;
    width: 100%;
}
.bipo_contenedortabla .bipo_contenedor_accordeon .panel-heading {
    color: #333;
    background-color: whitesmoke;
    border-color: #ddd;
}
.bipo_contenedortabla .bipo_contenedor_accordeon .panel-title {
    font-size: 14px !important;
    margin: 0;
    padding: 0;
    font-weight: 400;
    letter-spacing: 2px;
}
.bipo_contenedortabla .bipo_contenedor_accordeon .panel-title a {
    color: #000;
    padding: 20px 15px;
    display: block;
}
.bipo_contenedortabla .bipo_contenedor_accordeon .panel-title a:hover, .bipo_contenedortabla .bipo_contenedor_accordeon .panel-title a:focus {
    color: #d20a11;
    text-decoration: none;
}
.bipo_contenedortabla .bipo_contenedor_accordeon .panel-body {
    padding: 20px;
    font-size: 14px;
}

@media only screen and (max-width: 991px){
    .bipo_contenedortabla .cookie_desktop {
        display: none;
    }
    .bipo_contenedortabla .bipo_contenedor_accordeon {
        display: block;
    }
}
.fmConfIE .input-group-item{
	width: 100%;
}

.fmConfIE .input-group-item input:focus{
	z-index: auto;
}

.avisoModalIE .modal{
	background-color: rgba(0, 0, 0, 0.6);
	opacity: 1;
	z-index: 999999;

}

.avisoModalIE .modal-content{
	border-radius: 0;
  border: none;
}

.avisoModalIE .modal-dialog-avisoModalIE{
	position: fixed;
	width: 50%;
	left: 25%;
	top: 15%;
	text-align: left;
}

@media (max-width: 1280px){
	.avisoModalIE .modal-dialog-avisoModalIE{
		width: 70%;
		left: 15%;
	}
}

.avisoModalIE .modal-cabecera{
	display: flex;
	justify-content: space-between;
	background-color: #ebebe6;
	height: 150px;
      align-items: flex-end;
      border: none;
      border-radius: 0;
      padding-left: 50px;
      padding-right: 50px;
}

.avisoModalIE .modal-cabecera::before, .avisoModalIE .modal-cabecera::after{
	content: none;
}


.avisoModalIE .tituloAviso{
	color: #d20a11;
	font-weight: 300;
    font-size: 28px;
 	margin-top: 0px;
 	margin-right: 10px;
 	padding-top: 0px;
 	padding-bottom: 0px;
 	text-align: left;
}

.avisoModalIE .icono{

}


.avisoModalIE .tituloAviso span{
	font-weight: bold;
}

.avisoModalIE .modal-body{
	border-radius: 0;
	padding-left: 50px;
  padding-right: 50px;
}

.avisoModalIE .texto{
	margin-top: 30px;
}

.avisoModalIE .texto p{
	margin-bottom: 5px;
  font-size: 15px;
  letter-spacing: 1px;
  color: #000;
  font-weight: 500;
  line-height: 23px;
}

.avisoModalIE .enlaces{
	margin-top: 30px;
	margin-bottom: 30px;
}

.avisoModalIE .enlaces ul{
	padding-left: 0px;
}

.avisoModalIE .enlaces ul li{
	display: inline-block;
	background-color: #ebebe6;
  padding: 20px;
  margin-right: 10px;
  margin-top: 10px;
}

.avisoModalIE .enlaces ul li a{
  font-size: 15px;
  letter-spacing: 1px;
  color: #000;
  font-weight: 500;
  line-height: 23px;
  text-decoration: underline;
}

.avisoModalIE .enlaces ul li a:hover, .avisoModalIE .enlaces ul li a:focus{
	color: #d20a11;
}

.avisoModalIE .enlaces ul li a img{
	height: 20px;
  vertical-align: baseline;
  margin-left: 20px;
}

.avisoModalIE .idiomas{
}

.avisoModalIE .idiomas ul{
    font-size: 15px;
	padding-left: 0px;
}

.avisoModalIE .idiomas ul li{
	display: inline-block;
	padding: 0 5px;
	border-left: 1px solid black;
	margin-right: 0px;
}

.avisoModalIE .idiomas ul li a{
    font-size: 15px;
	color: #d20a11;
}

.avisoModalIE .idiomas ul li a:hover, .avisoModalIE .idiomas ul li a:focus{
	color: black;
}

.avisoModalIE .idiomas ul li:first-child{
	border-left: none;
	padding-left: 0px;
	padding-right: 5px;
}

.avisoModalIE .idiomas p{
	margin: 0px;
}

.avisoModalIE .idiomas a{
	text-decoration: underline!important;
	text-transform: capitalize;
}



#enlaceIdiomas{
	list-style-type: none;
	margin-bottom: 30px;
	display: inline-flex;
}

#enlaceIdiomas a {
    text-decoration: underline;
    font-size: 1em;
    letter-spacing: normal;
    color: #C51015;
    text-transform: capitalize;
}

#enlaceIdiomas .seleccionIdioma {
    text-decoration: none;
    cursor: pointer;
}

#enlaceIdiomas a:hover {
    color: #C51015;
}

#enlaceIdiomas li {
  	border-right: 1px solid black;
  	display:inline;
  	margin-right: 0.3em;
}

#enlaceIdiomas li:last-child {
  	border-right: none;
}

